import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import useMediaQuery from "../hooks/useMediaQuery"
import marked from "marked"

const Rapid = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
    containerClass: "swiper-container pb-5",
    nested: true,

    autoplay: false,
    // {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  const data = [
    {
      title: "Low-Code Custom Application Developement",
      description:
        "<p>Get your team out of the spreadsheets and in front of customers with low-code applications that streamline operations, improve customer service, and provide clean data for AI-driven insights.</p>",
      url: "/custom-quickbase-application-development/",
      imgUrl: "/dceec435af07d66ca7a1aa259a5bdfc91ea427ff-1280x960.jpg",
      direction: "ltr",
    },
    {
      title: "Integrate and Automate Workflows",
      description:
        "<p>Eliminate manual data entry by connecting your disparate systems. Accelerate your ROI with reduced labor costs, fewer mistakes, and real-time data insights.</p>",
      url: "/quickbase-integration-services/",
      imgUrl: "/d59e61ab873e78f8a1ee6e84ea00ce619c06e0c2-1280x959.jpg?w=570",
      direction: "rtl",
    },
  ]
  // Build
  return (
    //     <section id="it-consulting" className="it-consulting">
    //       <div className="container py-lg-4 py-md-5">
    //         <div className="row">
    //           <div className="col-lg-12 col-md-8 mx-auto text-center">
    //             <h2 className="my-3 font-weight-medium">
    //               Lead with Innovative Apps—Inspired By Your Team
    //             </h2>

    //             <p
    //               className="text-center mx-auto px-sm-2"
    //               style={{ maxWidth: "825px" }}
    //             >
    //               Redefine operations, revolutionize customer experiences, and scale
    //               output (<i>not costs</i>) while staying ahead of industry trends.
    //             </p>
    //           </div>
    //         </div>
    //         {isDesktop ? (
    //           <LazyLoadComponent>
    //             <div class="tabs pt-4">
    //               <input
    //                 type="radio"
    //                 class="tabs__radio"
    //                 name="tabs-example"
    //                 id="tab1"
    //                 checked
    //               />

    //               <label for="tab1" class="tabs__label mx-auto">
    //                 Launch in Weeks
    //               </label>

    //               <div class="tabs__content">
    //                 <div
    //                   className="row align-items-center mt-3 mb-5"
    //                   style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
    //                 >
    //                   <div className="col-lg-6 col-md-6 mt-4 mb-4">
    //                     <StaticImage
    //                       placeholder="blurred"
    //                       className="rounded d-flex w-100"
    //                       src="https://cdn.sanity.io/images/55lcecww/production/266e90416045094ae6335ee764a8e6065894e072-1920x1280.jpg"
    //                       alt="Launch in Weeks"
    //                       layout="constrained"
    //                       style={{ height: "20rem" }}
    //                       loading="lazy"
    //                       fetchpriority="low"
    //                     />
    //                   </div>

    //                   <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5 pb-5">
    //                     <h3 className="text-left-mobile" style={{ margin: "0" }}>
    //                       Launch in Weeks
    //                     </h3>
    //                     <p className="my-4 text-left-mobile">
    //                       Bring your ideas to life in weeks using expert consulting
    //                       paired with low-cost, lightweight tools that cut
    //                       deployment time (not quality) in half.
    //                     </p>

    //                     {/* <Link
    //                       className="btn btn-primary btn-arrow"
    //                       to="/technology-consulting/"
    //                     >
    //                       Start Your Analysis
    //                       <span className="d-none">
    //                         This is a redirection to quandary-build
    //                       </span>
    //                     </Link> */}
    //                   </div>
    //                 </div>
    //               </div>

    //               <input
    //                 type="radio"
    //                 class="tabs__radio"
    //                 name="tabs-example"
    //                 id="tab2"
    //               />
    //               <label for="tab2" class="tabs__label mx-auto">
    //                 Scale Your Output
    //               </label>

    //               <div class="tabs__content">
    //                 <div
    //                   className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
    //                   style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
    //                 >
    //                   {/* <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5"> */}
    //                   <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5 pb-5">
    //                     <h3 className="text-left-mobile" style={{ margin: "0" }}>
    //                       Scale Your Output
    //                     </h3>
    //                     <p className="my-4 text-left-mobile">
    //                       Grow your business without adding overhead by reimagining
    //                       and reworking long-established processes with emerging
    //                       tech.
    //                     </p>
    //                     {/* <Link
    //                       className="btn btn-primary btn-arrow"
    //                       to="/software-asset-management/"
    //                     >
    //                       Audit Your Tech Stack
    //                       <span className="d-none">
    //                         This is a redirection to quandary-connect
    //                       </span>
    //                     </Link> */}
    //                   </div>
    //                   <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
    //                     <StaticImage
    //                       placeholder="blurred"
    //                       className="rounded d-flex w-100"
    //                       src="https://cdn.sanity.io/images/55lcecww/production/44fb2966555821d69adf83595e5932d7904df57c-1920x1280.jpg"
    //                       alt="Scale Your Output"
    //                       layout="constrained"
    //                       style={{ height: "20rem" }}
    //                       loading="lazy"
    //                       fetchpriority="low"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>

    //               <input
    //                 type="radio"
    //                 class="tabs__radio"
    //                 name="tabs-example"
    //                 id="tab3"
    //                 checked
    //               />
    //               <label for="tab3" class="tabs__label mx-auto">
    //                 Empower Your Team
    //               </label>
    //               <div class="tabs__content">
    //                 <div
    //                   className="row align-items-center mt-3 mb-5"
    //                   style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
    //                 >
    //                   <div className="col-lg-6 col-md-6 mt-4 mb-4">
    //                     <StaticImage
    //                       placeholder="blurred"
    //                       className="rounded d-flex w-100"
    //                       src="https://cdn.sanity.io/images/55lcecww/production/68694b9a646bc1a42166c96555ecd686bd0c5c21-1920x1280.jpg"
    //                       alt=" Empower Your Team"
    //                       layout="constrained"
    //                       style={{ height: "20rem" }}
    //                       loading="lazy"
    //                       fetchpriority="low"
    //                     />
    //                   </div>

    //                   <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5 pb-5">
    //                     <h3 className="text-left-mobile" style={{ margin: "0" }}>
    //                       Empower Your Team
    //                     </h3>
    //                     <p className="my-4 text-left-mobile">
    //                       Attract and retain top talent with a workplace that
    //                       prioritizes innovation and encourages teams to focus on
    //                       exciting, high-impact work.
    //                     </p>
    //                     {/* <Link
    //                       className="btn btn-primary btn-arrow"
    //                       to="/citizen-development-support/
    //               "
    //                     >
    //                       Find Your AI Gaps
    //                     </Link> */}
    //                   </div>
    //                 </div>
    //               </div>
    //               <input
    //                 type="radio"
    //                 class="tabs__radio"
    //                 name="tabs-example"
    //                 id="tab4"
    //                 checked
    //               />
    //               <label for="tab4" class="tabs__label mx-auto">
    //                 Thrill Your Customers
    //               </label>
    //               <div class="tabs__content">
    //                 <div
    //                   className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
    //                   style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
    //                 >
    //                   <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5 pb-5">
    //                     <h3 className="text-left-mobile" style={{ margin: "0" }}>
    //                       Thrill Your Customers
    //                     </h3>

    //                     <p className="my-4 text-left-mobile">
    //                       Deliver new customer experiences that redefine
    //                       expectations, increase customer satisfaction, and get
    //                       noticed by your competitors and their customers.
    //                     </p>

    //                     {/* <Link
    //                       className="btn btn-primary btn-arrow mb-lg-0 mb-md-4 mb-5"
    //                       to="/quandary-build/"
    //                     >
    //                       How We Build
    //                     </Link> */}
    //                   </div>
    //                   <div className="col-lg-6 col-md-6 mt-4 mb-4">
    //                     <StaticImage
    //                       placeholder="blurred"
    //                       className="rounded d-flex"
    //                       src="https://cdn.sanity.io/images/55lcecww/production/d05b8679e3af330d585a3b0c8fd74fd0e7312709-1920x1280.jpg"
    //                       alt="Thrill Your Customers"
    //                       layout="constrained"
    //                       style={{ height: "20rem" }}
    //                       loading="lazy"
    //                       fetchpriority="low"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             {/*
    //             <div className="row align-items-center">
    //               <div className="col-lg-6 col-md-6 mt-4 mb-4">
    //                 <StaticImage
    //                   placeholder="blurred"
    //                   className="rounded d-flex w-100"
    //                   src="https://cdn.sanity.io/images/55lcecww/production/e90ceb8caee9906854df62ee9bcf372a7e9e56ad-1280x788.jpg?w=570"
    //                   alt="Application Development"
    //                   layout="constrained"
    //                   style={{ height: "20rem" }}
    //                   loading="lazy"
    //                   fetchpriority="low"
    //                 />
    //               </div>
    //               <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
    //                 <h3 className="text-left-mobile">
    //                   End-to-End Procurement Optimization
    //                 </h3>
    //                 <p className="my-4 text-left-mobile">
    //                   Looking to streamline invoicing? Want to manage your vendors
    //                   from one, simple platform? Eager to supercharge your entire
    //                   procurement process with automation? We transform your supply
    //                   chain into a strategic asset.
    //                 </p>
    //                 <Link
    //                   className="btn btn-primary btn-arrow"
    //                   to="/procurement-automation-services/"
    //                 >
    //                   Explore Procurement Automation
    //                   <span className="d-none">
    //                     This is a redirection to Procurement Automation
    //                   </span>
    //                 </Link>
    //               </div>
    //             </div> */}

    //             {/* <div
    //               className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
    //               style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
    //             >
    //               <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
    //                 <h3 className="text-left-mobile">
    //                   Offline Construction Management
    //                 </h3>
    //                 <p className="my-4 text-left-mobile">
    //                   Get custom construction management solutions that work
    //                   offline, so you can effortlessly manage your workforce. No
    //                   matter the job site. Don’t let WiFi access restrict your team.
    //                 </p>
    //                 <Link
    //                   className="btn btn-primary btn-arrow"
    //                   to="/offline-application-development-construction-management/"
    //                 >
    //                   Explore Construction Management
    //                 </Link>
    //               </div>
    //               <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
    //                 <StaticImage
    //                   placeholder="blurred"
    //                   className="rounded d-flex w-100"
    //                   src="https://cdn.sanity.io/images/55lcecww/production/574c4615dd06ab10e6cb8d2bd0c9dea134d8a37e-2000x1333.jpg"
    //                   alt="Construction Management"
    //                   layout="constrained"
    //                   style={{ height: "20rem" }}
    //                   loading="lazy"
    //                   fetchpriority="low"
    //                 />
    //               </div>
    //             </div> */}
    //           </LazyLoadComponent>
    //         ) : (
    //           <div className="mobile-swiper d-flex flex-column pt-2">
    //             <LazyLoadComponent>
    //               <div className="pt-md-5 pb-md-5">
    //                 <div>
    //                   <div className="col-12 text-center">
    //                     <h3 className="">Launch in Weeks</h3>
    //                     <p className="my-4">
    //                       Bring your ideas to life in weeks using expert consulting
    //                       paired with low-cost, lightweight tools that cut
    //                       deployment time (not quality) in half.
    //                     </p>

    //                     {/* <Link
    //                       className="btn btn-primary btn-arrow"
    //                       to="/technology-consulting/"
    //                     >
    //                       Start Your Analysis
    //                       <span className="d-none">
    //                         This is a redirection to quandary-build
    //                       </span>
    //                     </Link> */}
    //                   </div>
    //                   <div className="col-12">
    //                     {/* <StaticImage
    //                         placeholder="blurred"
    //                         className="rounded d-flex w-100"
    //                         src="https://cdn.sanity.io/images/55lcecww/production/6a384fbfcf147dea7e9056c75dae53153353f6e3-1280x960.jpg?w=570"
    //                         alt="Application Development"
    //                         layout="constrained"
    //                         loading="lazy"
    //                         fetchpriority="low"
    //                       /> */}
    //                     <img
    //                       placeholder="blurred"
    //                       className="rounded d-flex w-100 img-fluid"
    //                       src="https://cdn.sanity.io/images/55lcecww/production/266e90416045094ae6335ee764a8e6065894e072-1920x1280.jpg"
    //                       alt="Launch in Weeks
    // "
    //                       layout="constrained"
    //                       loading="lazy"
    //                       fetchpriority="low"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>

    //               <div className="pt-md-5 pb-md-5">
    //                 <div>
    //                   <div className="col-12 text-center">
    //                     <h3 className="">Scale Your Output</h3>
    //                     <p className="my-4 ">
    //                       Grow your business without adding overhead by reimagining
    //                       and reworking long-established processes with emerging
    //                       tech.
    //                     </p>
    //                     {/* <Link
    //                       className="btn btn-primary btn-arrow"
    //                       to="/software-asset-management/"
    //                     >
    //                       Audit Your Tech Stack
    //                       <span className="d-none">
    //                         This is a redirection to quandary-connect
    //                       </span>
    //                     </Link> */}
    //                   </div>
    //                   <div className="col-12">
    //                     <StaticImage
    //                       placeholder="blurred"
    //                       className="rounded d-flex w-100"
    //                       src="https://cdn.sanity.io/images/55lcecww/production/44fb2966555821d69adf83595e5932d7904df57c-1920x1280.jpg"
    //                       alt="Scale Your Output
    // "
    //                       layout="constrained"
    //                       style={{ height: "20rem" }}
    //                       loading="lazy"
    //                       fetchpriority="low"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>

    //               <div className="pt-md-5 pb-md-5">
    //                 <div>
    //                   <div className="col-12 text-center">
    //                     <h3 className="">Empower Your Team</h3>
    //                     <p className="my-4 ">
    //                       Attract and retain top talent with a workplace that
    //                       prioritizes innovation and encourages teams to focus on
    //                       exciting, high-impact work.
    //                     </p>
    //                     {/* <Link
    //                       className="btn btn-primary btn-arrow"
    //                       to="/citizen-development-support/"
    //                     >
    //                       Find Your AI Gaps
    //                       <span className="d-none">
    //                         This is a redirection to Procurement Automation
    //                       </span>
    //                     </Link> */}
    //                   </div>
    //                   <div className="col-12">
    //                     <img
    //                       placeholder="blurred"
    //                       className="rounded d-flex w-100"
    //                       src="https://cdn.sanity.io/images/55lcecww/production/68694b9a646bc1a42166c96555ecd686bd0c5c21-1920x1280.jpg"
    //                       alt="Empower Your Team"
    //                       layout="constrained"
    //                       loading="lazy"
    //                       fetchpriority="low"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>

    //               <div className="pt-md-5 pb-md-5">
    //                 <div>
    //                   <div className="col-12 text-center">
    //                     <h3 className="">Thrill Your Customers</h3>
    //                     <p className="my-4 ">
    //                       Deliver new customer experiences that redefine
    //                       expectations, increase customer satisfaction, and get
    //                       noticed by your competitors and their customers.
    //                     </p>
    //                     {/* <Link
    //                       className="btn btn-primary btn-arrow"
    //                       to="/quandary-build/"
    //                     >
    //                       How We Build
    //                     </Link> */}
    //                   </div>
    //                   <div className="col-12">
    //                     <StaticImage
    //                       placeholder="blurred"
    //                       className="rounded d-flex w-100"
    //                       src="https://cdn.sanity.io/images/55lcecww/production/d05b8679e3af330d585a3b0c8fd74fd0e7312709-1920x1280.jpg"
    //                       alt="Thrill Your Customers
    // "
    //                       layout="constrained"
    //                       style={{ height: "20rem" }}
    //                       loading="lazy"
    //                       fetchpriority="low"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>

    //               {/* <div className="swiper-slide">
    //                   <div>
    //                     <div className="col-12">
    //                       <img
    //                         placeholder="blurred"
    //                         className="rounded d-flex w-100"
    //                         src="https://cdn.sanity.io/images/55lcecww/production/a05d3486dd392b13a8c69d078aeb51fdd1b257c6-2000x1334.jpg"
    //                         alt="Citizen Development"
    //                         layout="constrained"
    //                         loading="lazy"
    //                         fetchpriority="low"
    //                       />
    //                     </div>
    //                     <div className="col-12 text-center">
    //                       <h3 className="text-left-mobile">
    //                         Citizen Development Governance
    //                       </h3>
    //                       <p className="my-4 text-left-mobile">
    //                         We help organizations stand up and govern citizen
    //                         development programs encouraging safe, scalable
    //                         innovation. Accomplish more. Risk less.
    //                       </p>
    //                       <Link
    //                         className="btn btn-primary btn-arrow"
    //                         to="/citizen-development-support/
    //               "
    //                       >
    //                         Explore Citizen Development Governance
    //                       </Link>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="swiper-slide">
    //                   <div>
    //                     <div className="col-12">
    //                       <StaticImage
    //                         placeholder="blurred"
    //                         className="rounded d-flex"
    //                         src="https://cdn.sanity.io/images/55lcecww/production/d9df676a86e906bcc9f49e85cb19a6ecd89aa386-810x608.jpg"
    //                         alt="Solution Provider"
    //                         layout="constrained"
    //                         style={{ height: "20rem" }}
    //                         loading="lazy"
    //                         fetchpriority="low"
    //                       />
    //                     </div>
    //                     <div className="col-12 text-center">
    //                       <h3 className="text-left-mobile">
    //                         Business Process Improvement
    //                       </h3>
    //                       <p className="my-4 text-left-mobile">
    //                         Fix broken systems and remove the manual processes to
    //                         increase your team's productivity and eliminate
    //                         unnecessary slowdowns and bottlenecks with automated
    //                         workflows.
    //                       </p>
    //                       <Link
    //                         className="btn btn-primary btn-arrow mb-lg-0 mb-md-4 mb-5"
    //                         to="/tech-consulting/"
    //                       >
    //                         Explore Business Process Improvement
    //                       </Link>
    //                     </div>
    //                   </div>
    //                 </div> */}
    //             </LazyLoadComponent>
    //           </div>
    //         )}
    //       </div>
    //     </section>

    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "1.5rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Lead with Innovative Apps—Inspired By Your Team
            </h2>
            <p
              className="text-center mx-auto px-sm-2"
              style={{ maxWidth: "825px" }}
            >
              Redefine operations, revolutionize customer experiences, and scale
              output (<i>not costs</i>) while staying ahead of industry trends.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label for="tab1" class="tabs__label mx-auto">
                Launch in Weeks
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/266e90416045094ae6335ee764a8e6065894e072-1920x1280.jpg"
                      alt="Launch in Weeks"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Launch in Weeks</h3>
                    <p className="my-4 text-left-mobile">
                      Bring your ideas to life in weeks using expert consulting
                      paired with low-cost, lightweight tools that cut
                      deployment time (not quality) in half.
                    </p>
                  </div>
                </div>
              </div>

              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label for="tab2" class="tabs__label mx-auto">
                Scale Your Output
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Scale Your Output</h3>
                    <p className="my-4 text-left-mobile">
                      Grow your business without adding overhead by reimagining
                      and reworking long-established processes with emerging
                      tech.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/44fb2966555821d69adf83595e5932d7904df57c-1920x1280.jpg"
                      alt="Scale Your Output"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>

              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label for="tab3" class="tabs__label mx-auto">
                Empower Your Team
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/68694b9a646bc1a42166c96555ecd686bd0c5c21-1920x1280.jpg"
                      alt="Empower Your Team"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Empower Your Team</h3>
                    <p className="my-4 text-left-mobile">
                      Attract and retain top talent with a workplace that
                      prioritizes innovation and encourages teams to focus on
                      exciting, high-impact work.
                    </p>
                  </div>
                </div>
              </div>

              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab4"
              />
              <label for="tab4" class="tabs__label mx-auto">
                Thrill Your Customers
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Thrill Your Customers</h3>
                    <p className="my-4 text-left-mobile">
                      Deliver new customer experiences that redefine
                      expectations, increase customer satisfaction, and get
                      noticed by your competitors and their customers.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/d05b8679e3af330d585a3b0c8fd74fd0e7312709-1920x1280.jpg"
                      alt="Thrill Your Customers"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column pt-2">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Launch in Weeks</h3>
                    <p className="my-4">
                      Bring your ideas to life in weeks using expert consulting
                      paired with low-cost, lightweight tools that cut
                      deployment time (not quality) in half.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/266e90416045094ae6335ee764a8e6065894e072-1920x1280.jpg"
                      alt="Launch in Weeks"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>

              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Scale Your Output</h3>
                    <p className="my-4">
                      Grow your business without adding overhead by reimagining
                      and reworking long-established processes with emerging
                      tech.
                    </p>
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/44fb2966555821d69adf83595e5932d7904df57c-1920x1280.jpg"
                      alt="Scale Your Output"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>

              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Empower Your Team</h3>
                    <p className="my-4">
                      Attract and retain top talent with a workplace that
                      prioritizes innovation and encourages teams to focus on
                      exciting, high-impact work.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/68694b9a646bc1a42166c96555ecd686bd0c5c21-1920x1280.jpg"
                      alt="Empower Your Team"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>

              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Thrill Your Customers</h3>
                    <p className="my-4">
                      Deliver new customer experiences that redefine
                      expectations, increase customer satisfaction, and get
                      noticed by your competitors and their customers.
                    </p>
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/d05b8679e3af330d585a3b0c8fd74fd0e7312709-1920x1280.jpg"
                      alt="Thrill Your Customers"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default Rapid
